import React, { useState } from 'react'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { adminVerification, deleteold, sendPaymentLink, send_update_link_api, createIDCard, remove_duplicate, cancelMembership } from '../../app/apis'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingBar from 'react-top-loading-bar'
import { default as ReactSelect } from "react-select";
import PhotoAdmin from './photo_admin';

const UserInformation = () => {
  const location = useLocation();
  // const { userinfo } = location.state;
  const userinfo = JSON.parse(localStorage.getItem("userinfo"));
  const [family, setFamily] = useState(userinfo.FamilyDetails)
  const [reject, setReject] = useState([])
  const [optionSelected, setOptionSelected] = useState(null)
  const isEmpty = Object.keys(family).length === 0;
  const [references, setReferences] = useState(userinfo.References)
  const isReferenceEmpty = Object.keys(references).length === 0;
  const [progress, setProgress] = useState(0)
  const isAgent = JSON.parse(localStorage.getItem('data'))["isAgent"];
  const [folder, setFolder] = useState({'Payment Receipt': null})
  const [url, setUrl] = useState("")
  const navigate = useNavigate()
  let agentInfo = userinfo.UpdatedBy
  let adminInfo = userinfo.VerifiedBy
  let fillInfo = userinfo.FormFilledBy
  if(fillInfo.name===undefined || fillInfo.name===null) fillInfo = {"name": "self", "email": "NA", "username": ""}

  // console.log(fillInfo)

  try{
    let a = agentInfo["name"][0]
  }catch(e){
    agentInfo = {"name": "", "email": "", "username": ""}
  }

  try{
    let a = adminInfo["name"][0]
  }catch(e){
    adminInfo = {"name": "", "email": "", "username": ""}
  }

  const checkObj  = {
    Area : userinfo.Area,
    City : userinfo.City,
    Phone : userinfo.Phone,
    NativeVillage : userinfo.NativeVillage,
    FirstName: userinfo.FirstName,
    Pincode: userinfo.Pincode
  }

  let send_update_link_api_call = async () => {
    setProgress(50)
    try{
      let res = await send_update_link_api({"app_id": userinfo["ApplicationID"]})
      console.log(res)
      toast.success('Update Link Sent Successfully', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }catch(e){
      console.log(e)
      toast.error('Something went wrong', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    setProgress(100)
  }

  let id_card_api_call = async () => {
    setProgress(50)
    try{
      let res = await createIDCard({"app_id": userinfo["ApplicationID"]})
      console.log(res)
      toast.success('ID Card Sent Successfully', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }catch(e){
      console.log(e)
      toast.error('Something went wrong', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }
    setProgress(100)
  }

  const impFieldCheck = Object.values(checkObj).every(x => x === null || x === '');


  const cancel_api_call = async (data) => {
    setProgress(50)
    try{
      await cancelMembership(data)
      navigate('/admin-dashboard')
      toast.success(' Cancelled Successfully', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }catch(e){
      toast.error('Something went Wrong :(', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    setProgress(100)
  }


  const verify = async (data) => {
    setProgress(50)

    let b = false
      try{
        let aa = optionSelected[0]["value"]
      }catch(e){
        b = true
      }

    if(userinfo.Pincode === '' || userinfo.Pincode === null){
      toast.error('Form is incomplete. Cannot verify or Reject', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }else if(data["reject"] && (IsOtherInReject() || b) && data["reason"].toString().trim()===""){    
        toast.error('Give reject reason', {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });  
    }else{
      try{
        let rr = ""
        if(data["reject"]){
          for(let kk of optionSelected){
            if(kk["value"]==="Other"){
              rr+=reject
              rr+=", "
            }else if(kk["value"] === "Permanent Reject"){
              rr+="Permanent Reject: "+ reject
              rr+=", "
            }else{
              rr+=kk["value"]
              rr+=", "
            }
          }
          data["reason"] = rr
        }

        await adminVerification(data)
        // console.log(response)
        navigate('/admin-dashboard')
        if(data["reject"]==true){
          toast.success(' Rejected Successfully', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }else{
          toast.success(' Verified Successfully', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
        }
        catch{
          toast.error('Something went Wrong :(', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
        }
    }
    setProgress(100)
    // setProgress(0)
  }

  let removeDuplicateAPICall = async(data) => {
    setProgress(50)
    let response = await remove_duplicate(data);
    // console.log(response)
    toast.success('Removed from Duplicate', {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
    setProgress(100)
  }

  let deleteAPICall = async(data) => {
    setProgress(50)
    let response = await deleteold(data);
    console.log(response)
    toast.success('Marked as Duplicate', {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
    setProgress(100)
  }

  let sendPaymentLinkAPICall = async () => {
    setProgress(50)
    if(userinfo.Verified===false){
      toast.error('Verify to send payment link', {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    }else{
      try{
        const response = await sendPaymentLink({ "ApplicationID": `${userinfo.ApplicationID}` })
        console.log(response)
        toast.success('Payment link sent Successfully', {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });
        }
      catch{
          toast.error('Something went Wrong :(', {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
      }
    }
    setProgress(100)
  }

  let IsOtherInReject = () => {
    if(optionSelected!==null){
      for(let i of optionSelected){
        if(i["value"]==="Other" || i["value"]==="Permanent Reject") return true
      }
    }
    return false 
  }

  const RejectReasons = [
    { value: "Not a KVO member", label: "Not a KVO member (Permanent reject)" },
    { value: "Permanent Reject", label: "Permanent reject" },
    { value: "Aadhar card image invalid or unclear", label: "Aadhar card image invalid or unclear" },
    { value: "User photo invalid or unclear", label: "User photo invalid or unclear" },
    { value: "Aadhar number is invalid", label: "Aadhar number is invalid" },
    { value: "Issues in residential address - recheck pincode, area and address", label: "Issues in residential address - recheck pincode, area and address" },
    { value: "Other", label: "Other" },
  ];


  return (
    <>
      <LoadingBar color='#f11946' progress={progress} />
      {userinfo.Rejected===false && userinfo.RejectReason!="" ? 
                  (
                    <div className='w-full'>
                      <div className='w-full my-2 mx-1 bg-red-400 p-2 text-white'>
                        UPDATED AFTER BEING REJECTED
                        <br></br>
                        {userinfo.RejectReason}
                      </div>
                    </div>
                  
                  ) :
                  <div></div>
              }

      {userinfo.Rejected ? 
                  (
                    <div className='w-full'>
                      <div className='w-full my-2 mx-1 bg-red-400 p-2 text-white'>
                        REJECTED!
                        <br></br>
                        {userinfo.RejectReason}
                      </div>
                      <button className='w-full bg-red-400 mt-2 text-white p-2' onClick={async ()=>{await send_update_link_api_call()}}> Send update link</button>
                    </div>
                  
                  ) :
                  <div></div>
              }

      {userinfo.MDOBool ? 
                  (
                    <div className='w-full'>
                      <div className='w-full my-2 mx-1 bg-red-400 p-2 text-white'>
                        DECLINED!!
                        <br></br>
                        {userinfo.MDO.value}
                        <br></br>
                        {userinfo.MDO.other}
                      </div>
                    </div>
                  
                  ) :
                  <div></div>
              }

      <div className="flex justify-center items-center flex-col w-auto my-10  rounded">
        <div className='flex justify-between bg-purple-600 text-white w-1/2 items-center m-1 p-3'>
          <div className='flex flex-row items-center justify-center'>
            <a href={userinfo.PhotoS3} target="_blank">
              <img src={userinfo.PhotoS3} alt="no image" className='h-[50px] w-[50px] rounded-full'
              />
            </a>
            <a href={userinfo.AadharS3} target="_blank"> <button className='ml-3 bg-white text-purple-900 px-4 py-1 rounded-lg'>
              Aadhaar/PAN
            </button>
            </a>
            {(userinfo.Paid && userinfo.Verified && userinfo.InvoiceLink!=="") ? (<a href={userinfo.InvoiceLink} target="_blank"> <button className='ml-3 bg-white text-purple-900 px-4 py-1 rounded-lg'>Payment Receipt</button></a>): (<></>)}
            {(userinfo.Paid && userinfo.Verified && userinfo.IDCARDLink!=="") ? (<a href={userinfo.IDCARDLink} target="_blank"> <button className='ml-3 bg-white text-purple-900 px-4 py-1 rounded-lg'>ID CARD</button></a>): (<></>)}
          </div>

          <div>
            {userinfo.Verified ? <span className='px-3 py-1 bg-green-600 text-white rounded-full mr-3'>Verified</span>
              : <span className='px-3 py-1 bg-red-600 text-white rounded-full mr-3'>Not Verified</span>
            }
            {userinfo.Paid ? <span className='px-3 py-1 bg-green-700 text-white rounded-full mr-3'>Paid</span>
              : <span className='px-3 py-1 bg-red-700 text-white rounded-full mr-3'>Not Paid</span>
            }
            {userinfo.New ? <span className='px-3 py-1 bg-teal-500 text-white rounded-full mr-3'>New</span>
              : <span className='px-3 py-1 bg-amber-800 text-white rounded-full mr-3'>Old</span>
            }

            {userinfo.New && userinfo.Self ? <span className='px-3 py-1 bg-green-900 text-white rounded-full mr-3'>self</span>
              : <span></span>
            }

            {userinfo.New && !userinfo.Self ? <span className='px-3 py-1 bg-green-900 text-white rounded-full mr-3'>agent/admin</span>
              : <span></span>
            }

          </div>
        </div>

        {
          agentInfo["name"]==="" && adminInfo["name"]==="" ? (
            <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
              <span>Filled By: {fillInfo.name + " (" + fillInfo.email + ")"}</span>
            </div>
          ) : (
            <div></div>  
          ) 
        }
        
        {
          agentInfo["name"]!=="" && adminInfo["name"]==="" ? (
            <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
              <span>Processed by: {agentInfo.name + " (" + agentInfo.email + ")"}</span>
              <span>Filled By: {fillInfo.name + " (" + fillInfo.email + ")"}</span>
            </div>
          ) : (
            <div></div>  
          ) 
        }

        {
          agentInfo["name"]==="" && adminInfo["name"]!=="" ? (
            <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
              <span>Verified by: {adminInfo.name + " (" + adminInfo.email + ")"}</span>
              <span>Filled By: {fillInfo.name + " (" + fillInfo.email + ")"}</span>
            </div>
          ) : (
            <div></div>  
          ) 
        }

        {
          agentInfo["name"]!=="" && adminInfo["name"]!=="" ? (
            <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
              <span>Processed by: {agentInfo.name + " (" + agentInfo.email + ")"}</span>
              <span>Verified by: {adminInfo.name + " (" + adminInfo.email + ")"}</span>
              <span>Filled By: {fillInfo.name + " (" + fillInfo.email + ")"}</span>
            </div>
          ) : (
            <div></div>  
          ) 
        }

        <div className='flex justify-around bg-purple-600 text-white w-1/2 items-center m-1 p-2'>
          <span>First Name : {userinfo.FirstName}</span>
          <span>Middle Name : {userinfo.MiddleName}</span>
          <span>Last Name : {userinfo.LastName}</span>
        </div>

        <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
          <span>Gender : {userinfo.Gender}</span>
          <span>Email ID : {userinfo.Email}</span>
          <span>Occupation : {userinfo.Occupation}</span>
        </div>
        <div className='flex justify-around bg-purple-600 text-white w-1/2 items-center m-1 p-2'>
          <span>Grandfather / Father in Law: {userinfo.GrandFatherName}</span>
          <span>Maiden village: {userinfo.MaidenVillage}</span>
        </div>
        <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
          <span>GovernmentID Type : {userinfo.GovernmentID}</span>
          <span>GovernmentID Number : {userinfo.AadharNumber}</span>
        </div>
        <div className='flex justify-around bg-purple-600 text-white w-1/2 items-center m-1 p-2'>
          <span>Member ID : {userinfo.MemberID === null ? "NA" : userinfo.MemberID}</span>
          <span>Application ID : {userinfo.ApplicationID}</span>
        </div>
        <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
          <span>Mobile : {userinfo.Mobile}</span>
          <span>DOB : {userinfo.DateOfBirth}</span>
          <span>Education Qualification : {userinfo.EducationQualification}</span>
        </div>
        <div className='flex justify-around bg-purple-600 text-white w-1/2 items-center m-1 p-2'>
          <span>Address: {userinfo.Address}</span>
        </div>
        <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
          <span>PinCode : {userinfo.Pincode}</span>
          <span>Native Village : {userinfo.NativeVillage}</span>
        </div>
        <div className='flex justify-around bg-purple-600 text-white w-1/2 items-center m-1 p-2'>
          <span>Blood Group: {userinfo.BloodGroups}</span>
          <span>Marital Status: {userinfo.MaritalStatus}</span>
        </div>
        <div className='flex justify-around bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
          <span>Area : {userinfo.Area}</span>
          <span>City : {userinfo.City}</span>
          <span>State: {userinfo.State}</span>
        </div>
        {isEmpty ? (
            <div className='flex flex-col bg-purple-600 text-white w-1/2 items-center m-1 p-2'>
            <div className='flex justify-between items-center w-full'>
              <h1 className='font-semibold text-xl'>Family Details</h1>
              <h3 className='font-semibold text-mb'>
                Family ID: {userinfo.FamilyID}
              </h3>
              <h3 className='font-semibold text-mb'>
                Members: 0
              </h3>
            </div>
          </div>
          ) : (
            <div className='flex flex-col bg-purple-600 text-white w-1/2 items-center m-1 p-2'>
              <div className='flex justify-between items-center w-full'>
                <h1 className='font-semibold text-xl'>Family Details</h1>
                <h3 className='font-semibold text-mb'>
                  Family ID: {userinfo.FamilyID}
                </h3>
                <h3 className='font-semibold text-mb'>
                  Members: {userinfo.FamilyDetails.length}
                </h3>
              </div>
              {family.map((data, key) => (
                <div className='flex justify-between items-center w-full'>
                  <p>Name : {data.FamilyMemberName}</p>
                  <p>Relation : {data.Relation}</p>
                  <p>DOB : {data.RelativeDateOfBirth}</p>

                </div>
              ))}
            </div>
          )
        }

        {isReferenceEmpty ? null :
          <div className='flex flex-col bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
            <div className='flex justify-between items-center w-full'>
              <h1 className='font-semibold text-xl'>References</h1>
            </div>
            {references.map((data, key) => (
              <div className='flex justify-between items-center w-full'>
                <p>Name : {data.name}</p>
                <p>Number : {data.number}</p>
                <p>Village : {data.designation}</p>

              </div>
            ))}

          </div>
        }

      {userinfo.Paid===true && userinfo.New===true? (
          <div className='flex flex-col bg-purple-100 text-purple w-1/2 items-center m-1 p-2'>
            <div className='flex justify-between items-center w-full'>
              <h1 className='font-semibold text-xl'>Razorpay Payment ID</h1>
              <h5>{userinfo.RazorpayPaymentID}</h5>
            </div>
          </div>
        ): (<div></div>)
      }

        {isAgent ? 
          (
            <div className='w-1/2 justify-center items-center'>
              <div className='w-full flex justify-center items-center'>
                <Link className='w-1/2 items-center' to={`/form/auth_${userinfo.ApplicationID}`} state={{ "userinfo": userinfo }} >
                  <button className='w-full my-2 mx-4 bg-purple-900 p-2 text-white'>
                    Update
                  </button>
                </Link>
                <button className='w-1/2 items-center my-2 mx-5 bg-purple-900 p-2 text-white' onClick={async () => {await deleteAPICall({"app_id": userinfo.ApplicationID})}}>
                    Mark as Duplicate
                </button>
              </div>
              </div>
          ) : userinfo.Duplicate ? (
            <div className='w-1/2 justify-center items-center'>
              <div className='w-full flex justify-center items-center'>
                <button className='w-1/2 my-2 mx-1 bg-red-900 p-2 text-white items-center' onClick={async () => {await removeDuplicateAPICall({"app_id": userinfo.ApplicationID})}}>
                  Remove from Duplicate
                </button>
              </div>
            </div>
          ) : 
          (
            <div className='w-1/2 justify-center items-center'>
              {userinfo.Duplicate ? (
                  <div className='w-full flex justify-center items-center'>
                    <button className='w-1/2 my-2 mx-1 bg-red-900 p-2 text-white items-center' onClick={async () => {await removeDuplicateAPICall({"app_id": userinfo.ApplicationID})}}>
                      Remove from Duplicate
                    </button>
                  </div>
                ) : null
              }
              <div className='w-full flex justify-center items-center'>
                <Link className='w-1/2 items-center' to={`/form/auth_${userinfo.ApplicationID}`} state={{ "userinfo": userinfo }} >
                  <button className='w-full my-2 mx-1 bg-purple-900 p-2 text-white'>
                    Update
                  </button>
                </Link>
                {userinfo.Paid ? null :
                  <button className='w-1/2 my-2 mx-1 bg-blue-600 p-2 text-white' onClick={ async () => {await sendPaymentLinkAPICall()}}>
                    Send Payment Link
                  </button>
                }
                {userinfo.Paid && userinfo.Verified ? 
                  <button className='w-1/2 my-2 mx-1 bg-blue-600 p-2 text-white' onClick={ async () => {await id_card_api_call()}}>
                    Create ID Card
                  </button>
               :
                  null
                }
              </div>
              {userinfo.Verified ? null : <div className='flex w-full flex-col justify-center items-center mt-3'>
                <div className='flex w-full'>
                  <div className='w-5/6'>
                    <ReactSelect
                      options={RejectReasons}
                      isMulti
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={(selected) => {setOptionSelected(selected)}}
                      allowSelectAll={true}
                      value={optionSelected}
                    />
                    {
                      IsOtherInReject() ? (
                        <input type="text" className={'w-full p-2 border border-purple-200'} placeholder='Please enter Reject Reason here' onChange={e => setReject(e.target.value)} />
                      ) : (<div></div>)
                    }
                  </div>
                  <div className='w-1/6'>
                    <button className='p-2 bg-red-600 w-full text-white'
                      onClick={ async () => await verify({ "app_id": userinfo.ApplicationID, "reject": true, "reason":reject})}>
                        Reject
                    </button>
                  </div>
                </div>
                 <PhotoAdmin question={"Add Payment Receipt"} appid={userinfo.ApplicationID} folder={folder} setFolder={setFolder} setUrl={setUrl}/>
                <button className='w-full bg-green-500 mt-2 text-white p-2 ' 
                  onClick={ async () => await verify({ "app_id": userinfo.ApplicationID, "receipt": url,  "reject": false, "reason":"" })}>
                    Verify
                </button>
              </div>}

              { userinfo.Verified && userinfo.Paid ? (
                    <div className='flex w-full'>
                      <div className='w-5/6'>
                      <input type="text" className={'w-full p-2 border border-purple-200'} placeholder='Please enter cancel reason here' onChange={e => setReject(e.target.value)} />
                      </div>
                      <div className='w-1/6'>
                        <button className='p-2 bg-red-600 w-full text-white'
                          onClick={ async () => await cancel_api_call({ "app_id": userinfo.ApplicationID, "reject": true, "reason":reject})}>
                            Cancel
                        </button>
                      </div>
                    </div>
                  ) : (<></>)}

            </div>
          ) 
        }
      </div>
    </>
  )
}

export default UserInformation

