import React from 'react'
import Navbar from '../components/Navbar'
const Landing = () => {
    return (
        <>
            <Navbar />
            {/* 404 page */}
            <div className="flex justify-center items-center h-screen bg-gradient-to-r from-purple-500 to-fuchsia-500">
                <div className="text-center">
                    <h1 className="text-9xl text-white font-bold">404</h1>
                    <h2 className="text-2xl text-white">Page Not Found</h2>
                </div>
            </div>

        </>
    )
}

export default Landing