import { Routes, Route, Outlet } from 'react-router-dom'
import { Login } from './features/auth/Login';
import RequireAuth from './features/auth/RequiredAuth';
import Form from './features/form/Form';
import Dashboard from './components/dashboard-section/Dashboard';
import Payment from './components/Payment';
import UserInformation from './components/dashboard-section/UserInformation';
import AgentBucketInfo from './components/agent-dashboard-section/AgentBucketInfo';
import AgentDashboard from './components/agent-dashboard-section/AgentDashboard';
import { ToastContainer } from "react-toastify";
import FormVariant from './features/form/FormVariant';
import UpdateForm from './features/form/UpdateForm';
import ThankYou from './components/thankyou';
import ID from './components/id';
import NotFound from './components/NotFound';
import Search from './components/Search';

export const App = () => {  
  return (
    <>
    <div className="">
      <ToastContainer autoClose={3000} hideProgressBar />
      {/* <NetworkStatus/> */}
    </div>
    <Routes>
      <Route path="/" element={<Outlet />}>
        {/* public routes */}
        <Route index element={<Login />} />
        {/* <Route path='comp' element={<Components />} /> */}
        {/* <Route path='landing' element={<Landing />} /> */}
        <Route path="search" element={<Search />} />
        <Route path='payment' element={<Payment />} />
        <Route path='end' element={<ThankYou/>}/> 
        <Route path='id' element={<ID/>}/>
        {/* protected routes */}
        <Route path="form/:type" element={<Form/>} />
        <Route path="info/:id" element={<UserInformation/>} />
        <Route path="bucket/info/:id" element={<AgentBucketInfo/>} />
        <Route path='payment' element={<Payment />} />
        <Route path='formvariant/:type' element={<FormVariant/>}/>
        <Route path='update/:type' element={<UpdateForm/>}/>
        <Route element={<RequireAuth />}>
          <Route path='admin-dashboard'element={<Dashboard />} />
          <Route path='agent-dashboard'element={<AgentDashboard />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
    </>
  );
}